import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialComponentsModule } from '../material-modules.module';
import { SharedModule } from '../shared.module';
import { DynamicEmbeddedComponentComponent } from './components/dynamic-embedded-component/dynamic-embedded-component.component';
import { DynamicDropPageIndexDirective } from './components/dynamic-paginator/dynamic-drop-page-index.directive';
import { DynamicPaginatorComponent } from './components/dynamic-paginator/dynamic-paginator.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { DynamicCustomColumnDirective } from './directives/dynamic-custom-column/dynamic-custom-column.directive';
import { DynamicTableWrapperComponent } from './components/dynamic-table-wrapper/dynamic-table-wrapper.component';
import { DynamicPropsInputDirective } from './directives/dynamic-props-input/dynamic-props-input.directive';
import { DynamicTwoLinesColumnDirective } from './directives/dynamic-two-lines-column/dynamic-two-lines-column.directive';
import { DynamicTwoLinesColumnComponent } from './directives/dynamic-two-lines-column/dynamic-two-lines-column.component';
import { DynamicRowIndexDirective } from './directives/dynamic-row-index/dynamic-row-index.directive';
import { DynamicRowIndexComponent } from './directives/dynamic-row-index/dynamic-row-index.component';

@NgModule({
  declarations: [
    DynamicPaginatorComponent,
    DynamicTableComponent,
    DynamicCustomColumnDirective,
    DynamicEmbeddedComponentComponent,
    DynamicDropPageIndexDirective,
    DynamicTableWrapperComponent,
    DynamicPropsInputDirective,
    DynamicTwoLinesColumnDirective,
    DynamicTwoLinesColumnComponent,
    DynamicRowIndexDirective,
    DynamicRowIndexComponent,
  ],
  imports: [CommonModule, MaterialComponentsModule, FormsModule, SharedModule],
  exports: [
    DynamicPaginatorComponent,
    DynamicTableComponent,
    DynamicCustomColumnDirective,
    DynamicDropPageIndexDirective,
    DynamicTableWrapperComponent,
    DynamicPropsInputDirective,
    DynamicTwoLinesColumnDirective,
    DynamicTwoLinesColumnComponent,
    DynamicRowIndexDirective,
    DynamicRowIndexComponent,
  ],
})
export class TableModule {}
