import { Directive, DoCheck, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kpHideIfEmpty]',
})
export class HideIfEmptyDirective implements DoCheck {
  private readonly element: HTMLElement;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.element = this.elementRef.nativeElement;
  }

  public ngDoCheck(): void {
    if (this.isEmpty(this.element)) {
      this.renderer.setStyle(this.element, 'display', 'none');
      return;
    }

    this.renderer.removeStyle(this.element, 'display');
  }

  private isEmpty(element: HTMLElement): boolean {
    return !this.hasContentProjection(element); //element.textContent.trim() === '';
  }

  private hasContentProjection(element: HTMLElement): boolean {
    const detailContentProjection: NodeList = element.childNodes;
    for (let x = 0; x < detailContentProjection.length; x++) {
      if (detailContentProjection.item(x).nodeType !== 8) {
        return true; // nodeType == 8 means is a comment
      }
    }

    return false;
  }
}
