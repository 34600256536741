import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kp-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageWrapperComponent {
  @Input() public pageTitle: string | null;
  @Input() public goBack: string | null = null;
}
