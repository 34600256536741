import { Component, OnInit } from '@angular/core';
import { DateService } from '@core/services/business/utils/date.service';
import { DomService } from '@core/services/business/utils/dom.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'kp-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(
    private dateService: DateService,
    private domService: DomService,
  ) {
    this.dateService.setLocale('ru');
  }

  ngOnInit() {
    this._documentClickListener();
    this._documentScrollListener();
  }

  private _documentClickListener() {
    fromEvent<MouseEvent>(this.domService.document, 'click', { capture: true })
      .pipe(untilDestroyed(this))
      .subscribe((event: MouseEvent) => {
        this.domService.clickOnDocument$.next(event);
      });
  }

  private _documentScrollListener() {
    fromEvent<Event>(this.domService.document, 'scroll', { capture: true })
      .pipe(untilDestroyed(this))
      .subscribe((event: Event) => {
        this.domService.scrollOnDocument$.next(event);
      });
  }
}
