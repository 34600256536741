<mat-form-field
  appearance="outline"
  class="autocomplite-select no-padding"
  [class.mat-form-field-invalid]="(ngControl.touched || form.touched) && ngControl.hasError(customError)"
>
  <mat-label *ngIf="!ngControl.disabled">{{ label }}</mat-label>
  <input
    type="text"
    [errorStateMatcher]="matcher"
    matInput
    autocomplete="on"
    [formControl]="form"
    [matAutocomplete]="auto"
    (keyup)="onKeypress()"
  />
  <kp-svg class="arrow" key="chevronDownBlackIcon" size="24px"></kp-svg>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option
      (click)="onSelect($event, option.value)"
      *ngFor="let option of options$ | async; trackBy: trackBy"
      [value]="option.name || option.value"
      >{{ option.name || option.value }}</mat-option
    >
  </mat-autocomplete>

  <mat-error class="error-msg" *ngIf="ngControl.touched && ngControl.hasError(customError)">{{
    ngControl?.getError(customError)
  }}</mat-error>
</mat-form-field>
