import { IEnvironment } from '@shared/models/common/environment.model';

export const environment: IEnvironment = {
  sentryEnv: '',
  sentryDSN: '',
  production: true,
  apiUrl: '',
  socketUrl: '',
  apiVersionPath: '',
  firebaseConfig: {
    apiKey: "AIzaSyAZvkJZ12v8J8CCE_H0rBdAnZ89HCa6qrQ",
    authDomain: "opora-32a08.firebaseapp.com",
    projectId: "opora-32a08",
    storageBucket: "opora-32a08.firebasestorage.app",
    messagingSenderId: "202679851535",
    appId: "1:202679851535:web:f031174e1713711f5ce17a",
    measurementId: "G-MJKCQ4572F"
  },
};
