import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISelectOption } from '@shared/models/select.model';
import {OverlayRef} from '@angular/cdk/overlay';

@Component({
  selector: 'kp-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  @Input() options: ISelectOption[] = [];
  @Input() separateFirstElement: boolean = false;
  @Input() separateLastElement: boolean = false;
  @Input() overlayRef: OverlayRef;

  @Output() optionClicked: EventEmitter<ISelectOption> = new EventEmitter<ISelectOption>();

  defaultIconColor: string = '#0053B2';
  defaultTextColor: string = '#171619';
}
